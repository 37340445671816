import { MAINTAIN_PERMISSION, LINE_PERFORMANCE_SHIFT_TIMES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.showTable ? _c("list-composer", { attrs: { "title": "Shift Times", "search-by": "FacilityName,AreaName,Code,ShiftDescription", "create-button-text": "Add Shift Time", "scroll": { x: 1200 }, "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-1", attrs: { "icon": "edit", "size": "small", "type": "link", "disabled": record.isInactive }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e()];
  } }], null, false, 2373886333) }, [_c("text-field", { key: "FacilityCode", attrs: { "data-index": "facilityName", "title": "Facility", "sorter": true } }), _c("text-field", { key: "AreaCode", attrs: { "data-index": "areaName", "title": "Department", "sorter": true } }), _c("text-field", { key: "Code", attrs: { "data-index": "code", "title": "Shift ID", "sorter": true } }), _c("text-field", { key: "ShiftDescription", attrs: { "data-index": "shiftDescription", "title": "Description" } }), _c("text-field", { key: "StartTime", attrs: { "data-index": "startTime", "parse": _vm.parseDate, "title": "Start Time", "sorter": true } }), _c("text-field", { key: "FinishTime", attrs: { "data-index": "finishTime", "parse": _vm.parseDate, "title": "Finish Time", "sorter": true } }), _c("text-field", { key: "ShiftHours", attrs: { "data-index": "shiftHours", "title": "Shift Hours", "width": 122, "sorter": true } }), _c("text-field", { key: "Labour", attrs: { "data-index": "labour", "title": "Labour", "sorter": true } })], 1) : _vm._e();
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListShiftTimes",
  inject: ["crud", "facility", "getArea", "can"],
  data() {
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      permissions: { maintain: MAINTAIN_PERMISSION },
      apiUrl: apiUrl2,
      showTable: false,
      customQuery: ""
    };
  },
  computed: {
    area() {
      return this.getArea();
    }
  },
  watch: {
    area(newVal) {
      this.crud.setFilter("AreaID", {
        operator: "Eq",
        value: newVal ? newVal : -1
      });
      this.setListPagination();
      this.crud.fetchList();
    }
  },
  created() {
    this.crud.setFilter("AreaID", {
      operator: "Eq",
      value: this.area ? this.area : -1
    });
    this.setListPagination();
    this.crud.fetchList();
    this.showTable = true;
  },
  methods: {
    parseDate(value) {
      if (!value)
        return "";
      const indexString = value.lastIndexOf(":");
      return value.slice(0, indexString);
    },
    generateLabel(facility) {
      return facility.description ? `${facility.name} - ${facility.description}` : `${facility.name}`;
    },
    setListPagination() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListShiftTimes = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-shift-times" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "identities.common.facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.shifts", "api-url": _vm.apiUrl, "create-route": "/line-performance/shifts/create", "edit-route": "/line-performance/shifts/:id", "page": _vm.page } }, [_c("list-shift-times")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "ShiftTimes",
  components: {
    ListShiftTimes
  },
  data() {
    return {
      page: LINE_PERFORMANCE_SHIFT_TIMES,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "shift-times",
          title: "Shift Times",
          path: "/line-performance/shifts"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
